import { template as template_0cd0a8d74408409d95d59dd442d72498 } from "@ember/template-compiler";
import repeat from 'ember-composable-helpers/helpers/repeat';
import LegacyCard from '@smile-io/ember-shopify-polaris/components/legacy-card';
import SkeletonBodyText from '@smile-io/ember-shopify-polaris/components/skeleton-body-text';
import { or } from 'ember-truth-helpers';
export default template_0cd0a8d74408409d95d59dd442d72498(`
  {{#each (repeat (or @count 3))}}
    <LegacyCard
      @sectioned={{true}}
      @title={{or @title (component SkeletonBodyText lines=1)}}
      ...attributes
    >
      <SkeletonBodyText @lines={{or @skeletonLines 3}} />
    </LegacyCard>
  {{/each}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
