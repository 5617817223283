import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class RestrictedAccessOverLimitRoute extends Route {
  @service router;
  @service sesh;

  beforeModel() {
    super.beforeModel(...arguments);

    if (!this.sesh.account?.shouldSeeLockoutScreen) {
      this.router.replaceWith('home');
    }
  }

  activate() {
    super.activate(...arguments);
    this.controllerFor('application').disableNav();
  }

  deactivate() {
    super.deactivate(...arguments);
    this.controllerFor('application').enableNav();
  }
}
