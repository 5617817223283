import { template as template_41834f96737f4b078b1b7a17a6e62d3e } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { action } from '@ember/object';
import { Box, Button, Card, EmptyState, InlineStack, Layout, Page } from '@smile-io/ember-shopify-polaris';
import { openHelpWidget } from 'smile-admin/helpers/open-help-widget';
import { hash } from '@ember/helper';
export default class PlanCanceled extends Component {
    @service
    router;
    @action
    transitionToPricingPlans() {
        this.router.transitionTo('settings.billing.pricing-plans');
    }
    static{
        template_41834f96737f4b078b1b7a17a6e62d3e(`
    <Page>
      <Layout as |layout|>
        <layout.Section>
          <Box @paddingBlockStart="1000">
            <Card>
              <EmptyState
                @heading="Your plan has been canceled"
                @image="/assets/illustrations/chat.svg"
                @largeImage="/assets/illustrations/chat.svg"
                @action={{hash
                  content="Choose a plan"
                  onAction=this.transitionToPricingPlans
                }}
              >
                <p>
                  To continue managing your loyalty program and access new
                  features, please choose a plan. Your loyalty program is turned
                  off.
                </p>
              </EmptyState>
            </Card>
          </Box>
        </layout.Section>

        <layout.Section>
          <Box @paddingBlockStart="1000">
            <InlineStack @align="center" @gap="100">
              Have questions?
              <Button @variant="plain" @onClick={{openHelpWidget}}>Contact
                support</Button>
            </InlineStack>
          </Box>
        </layout.Section>
      </Layout>
    </Page>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
